export default function Experts() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="14" r="3" fill="white"/>
            <path d="M21 23H3C1.89543 23 1 22.1046 1 21V11C1 9.89543 1.89543 9 3 9H21C22.1046 9 23 9.89543 23 11V21C23 22.1046 22.1046 23 21 23Z" stroke="white" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6L3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 2L5 2C5 0.89543 5.89543 0 7 0H17C18.1046 0 19 0.895431 19 2Z" fill="white"/>
            <mask id="mask0_2890_11518" maskUnits="userSpaceOnUse" x="6" y="18" width="12" height="12">
            <circle cx="12" cy="24" r="6" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2890_11518)">
            <path d="M21 23H3C1.89543 23 1 22.1046 1 21V11C1 9.89543 1.89543 9 3 9H21C22.1046 9 23 9.89543 23 11V21C23 22.1046 22.1046 23 21 23Z" fill="white"/>
            </g>
        </svg>
    )
}

