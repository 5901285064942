import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import Task from "../../components/task";
import Calendar from "../../images/Calendar";
import Critical from "../../images/Critical";
import Filter from "../../images/Fiilter"
// import From from "../../images/From";
// import People from "../../images/People"
// eslint-disable-next-line
import store from "../../store";
import './index.css'
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import expertTask from "../../mockData/expertTasks";
import toHumanFormat from "../../utils/toHumanFormat";
import config from "../../config";
import CustomCalendar from "../../components/calendar";
import Modal from "../../components/modal";
import { useTelegram } from "../../hooks/useTelegram";
import WordsNormalize from "../../utils/wordsNormalize";
import ArchiveWhite from "../../images/ArchiveWhite";
import ArchiveBlue from "../../images/ArchiveBlue";
import BottomNotify from "../../components/bottomNotify";
import telegramAnimoji from '../../images/telegram-animoji.json'
import Lottie from 'lottie-react'
import ResetFilter from "../../images/resetFilter";
// eslint-disable-next-line
import Arrow from "../../images/Arrow";
import Fire from "../../images/Fire";
import prettySmile from '../../images/pretty_smile.json'
import close_eye from '../../images/close_eye.json'
import noFindedTask from '../../images/sixth_point.json'

import { useAmplitude } from "../../hooks/useAmplitude";
import ExpertTask from "../../components/expertTask";
import { setCurrentPage } from "../../slices/appSlice";
import Experts from "../../images/Experts";

export default function MainPage() {

    const state = useSelector((store) => store.userSlice);
    const app = useSelector((store) => store.appSlice);

    const delay = 1000;

    const isFirstLoad = useRef(0);

    
    // const [isAllActive, setIsAllActive] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    // eslint-disable-next-line
    const [isPersonalActive, setIsPersonalActive] = useState(true);
    const [activeFilters, setActiveFilter] = useState({});
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [taskList, setTaskList] = useState({});
    const [expertTaskList, setExpertTaskList] = useState({});
    const [donedExpertTasks, setDonedExpertTask] = useState({});
    const [isOpenExpertList, setIsOpenExpertList] = useState(false);
    const [isLoadedContent, setIsLoadedContent] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [activeDeleteTask, setActiveDeleteTask] = useState();
    const [outDateTasks, setOutDateTasks] = useState({});
    const [isOutdatedActive, setIsOutdatedActive] = useState(false);
    const [archiveTasks, setArchiveTasks] = useState([]);
    const [isBottomBtn, setIsBottomBtn] = useState(false);

    const trackEvent = useAmplitude();
    const dispatch = useDispatch();

    const ref = useRef(null);
    const closeEye = useRef(null);
    const smileRef = useRef(null);

    const src = config.src.split('/').splice(0, 3).join('/');

    useEffect(() => {
        if(ref.current) {
            ref.current.stop()
            setTimeout(() => {
                ref.current.play()
            }, 450)
        }
    //eslint-next-line-ignore
    }, []);
    
    useEffect(() => {
        if(app?.currentPage?.indexOf('expert') !== -1) {
            setIsOpenExpertList(true);
        }
        dispatch(setCurrentPage(window.location.href));
    // eslint-disable-next-line
    }, [app.currentPage])

    useEffect(() => {
        if(closeEye.current && isOpenExpertList) {
            closeEye.current.stop()
            setTimeout(() => {
                closeEye.current.play()
            }, 450)
        }
    //eslint-next-line-ignore
    }, [isOpenExpertList]);


    useEffect(() => {
        if(smileRef.current && activeFilters?.archive && archiveTasks.length) {
            smileRef.current.stop()
            setTimeout(() => {
                smileRef.current.play()
            }, 450)
        }
    //eslint-disable-next-line
    }, [activeFilters?.archive, archiveTasks.length]);

    const { tg } = useTelegram();

    const filtersList = [
        {
            icon: <Calendar />,
            text: 'дата',
            id: 'date',
        },
        {
            icon: <Critical />,
            text: 'важное',
            id: 'critical',
        },
        {
            icon: <ArchiveBlue />,
            text: 'архив',
            id: 'archive',
            activeIcon: <ArchiveWhite />
        },
        {
            icon: <ResetFilter />,
            id: 'resetFilter',
        },
        // {
        //     icon: <From />,
        //     text: 'от кого',
        //     id: 'from',
        //     secondIcon: <People />
        // },
        // {
        //     icon: <From />,
        //     text: 'кому',
        //     id: 'to',
        //     secondIcon: <People />
        // },
    ]
    
    const reducedFiltersList = [
        {
            icon: <Calendar />,
            text: 'дата',
            id: 'date',
        },
        {
            icon: <ArchiveBlue />,
            text: 'архив',
            id: 'archive',
            activeIcon: <ArchiveWhite />
        },
        {
            icon: <ResetFilter />,
            id: 'resetFilter',
        },
    ]
    const checkDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const nowDate = new Date();

        const nowYear = nowDate.getFullYear();
        const nowMonth = nowDate.getMonth();
        const nowDay = nowDate.getDate();

        const tomorrowDate = new Date(nowDate.getTime() + (24 * 60 * 60 * 1000));

        const tomorrowYear = tomorrowDate.getFullYear();
        const tomorrowMonth = tomorrowDate.getMonth();
        const tomorrowDay = tomorrowDate.getDate();

        if (
            year === nowYear
            && month === nowMonth
            && day === nowDay
        ) {
            return 'Сегодня'
        } else if (nowDate > date) {
            return 'Просрочено';
        } else if (
            year === tomorrowYear
            && month === tomorrowMonth
            && day === tomorrowDay
        ) {
            return 'Завтра';
        } else {
            return `${date.getDate()} ${toHumanFormat(date.getMonth())}`;
        };
    } 

    const backBtnFunction = () => {
        setIsOutdatedActive(false);
    }

    const normalizeBackData = (json) => {
        return [...json]
        .map((element) => {
            return ({...element, deadline_date: new Date(element.deadline_date.replace(' ', 'T'))})
        })
        .sort((task1, task2) => task1.deadline_date > task2.deadline_date ? 1 : -1)
    }

    const loadClosedTask = () => {
        setIsLoadedContent(false);
        fetch(`${config.src}/tasks/closed/${state.telegram_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async (res) => {
            const json = await res.json();
            if(json.length > 0) {
                setArchiveTasks([...normalizeBackData(json)])
            }
        })
        .catch((err) => {
            console.log('err:', err);
        })
        .finally(() => {
            setIsLoadedContent(true);
        })
    }

    useEffect(() => {
        if(!isOutdatedActive) {
            tg.BackButton.offClick(backBtnFunction)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [isOutdatedActive])

    useEffect(() => {
        return() => {
            tg.BackButton.offClick(backBtnFunction)
            tg.BackButton.hide()
        }
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(navigator.appVersion.indexOf('iPhone') !== -1 && navigator.appVersion.indexOf('Android') !== -1) {
            setIsBottomBtn(true)
        }
    // eslint-disable-next-line
    }, [navigator.appVersion])

    let navigate = useNavigate();

    const loadExpertsTasks  = () => {
        setIsLoadedContent(false);
        fetch(`${config.src}/supertasks/executor/${state.telegram_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async (res) => {
            const json = await res.json();
            const normal = {};
            const doned = {};
            json.sort((a, b) => new Date(a.deadline_date) - new Date(b.deadline_date)).forEach((el) => {
                if(el.status_id === 'done' || el.status_id === 'closed'){
                    if(doned[el.author_id]) {
                        doned[el.author_id].push(el)
                    } else {
                        doned[el.author_id] = [el]
                    }
                } else {
                    if(normal[el.author_id]) {
                        normal[el.author_id].push(el)
                    } else {
                        normal[el.author_id] = [el]
                    }
                }
            })
            setDonedExpertTask({...doned})
            setExpertTaskList({...normal});
            setTimeout(() => {
                setIsLoadedContent(true);
            }, 200)
        })
    }

    const loadTasks = () => {
        fetch(`${config.src}/tasks/opened/${state.telegram_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(async (res) => {
            const json = await res.json();
            const newObject = {};
            const newOutDateTasks = {};
            setTaskList({});
            if(json.length > 0) {
                normalizeBackData(json)
                .forEach((element, index) => {
                    element.humanDate = checkDate(element.deadline_date);

                    if(element.humanDate === 'Просрочено'){
                        newOutDateTasks[element.humanDate]
                        ? newOutDateTasks[element.humanDate] = [...newOutDateTasks[element.humanDate], element]
                        : newOutDateTasks[element.humanDate] = [element];
                    } else {
                        newObject[element.humanDate]
                        ? newObject[element.humanDate] = [...newObject[element.humanDate], element]
                        : newObject[element.humanDate] = [element];
                    }                    
                })
                setOutDateTasks(newOutDateTasks)
                setTaskList(newObject);
        }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setTimeout(() => {
                setIsLoadedContent(true);
            }, 200)
        })
    }

    useEffect(() => {
        if(state.telegram_id && isFirstLoad.current === 0) {
            setIsLoadedContent(false);
            isFirstLoad.current = isFirstLoad.current + 1;
            setTimeout(() => {
                loadTasks();
                loadExpertsTasks();
            }, delay)
        }
    // eslint-disable-next-line
    }, [state.telegram_id]);

    useEffect(() => {
        removeElement(['date', 'critical']);
        if (activeFilters?.archive && !isOpenExpertList) {
            loadClosedTask();
        }
    // eslint-disable-next-line
    }, [activeFilters?.archive])

    const removeElement = (id) => {
        const newCurrentFilters = Object.assign(activeFilters, {})
        if(typeof id === 'string') {
            delete newCurrentFilters[id];
        } else {
            id.forEach(element => {
                delete newCurrentFilters[element];
            })
        }
        setActiveFilter({...newCurrentFilters});
    }

    const elementsFilters = (arr) => {
        return arr
            .filter((taskListElement) => activeFilters.critical ? taskListElement.priority === 1 : true)
            .filter((taskListElement) => activeFilters.date ? taskListElement.deadline_date.getDate() === activeFilters.date.getDate()
                && taskListElement.deadline_date.getMonth() === activeFilters.date.getMonth()
                && taskListElement.deadline_date.getFullYear() === activeFilters.date.getFullYear() : true)
    }

    const renderExpertTask = (obj) => {
        return (
            <>
                {/* <div
                    className="content__tasklist__title absolute"
                >Задачи от экспертов</div> */}
                {
                    Object.values(obj).map((el) => {
                        const filtredMass = el
                            .filter((taskListElement) => {
                                const date = new Date(taskListElement.deadline_date);
                                return activeFilters.date ? date.getDate() === activeFilters.date.getDate()
                                && date.getMonth() === activeFilters.date.getMonth()
                                && date.getFullYear() === activeFilters.date.getFullYear() : true
                            })
                        return (
                            <>
                                {
                                    filtredMass.length > 0 ? (
                                        <div className="expert__wrapper">
                                            <div className="expert__header">
                                                <div style={{backgroundImage: el[0]?.expert?.photo ? `url(${src}/avatars/${el[0]?.expert?.photo})` : 'none'}} className="expert__avatar">{ !el[0]?.expert?.photo && el[0].author_name[0]}</div>
                                                <div className="expert__content">
                                                    <div className="expert__name">{el[0].author_name}</div>
                                                    <div className="expert__description">{el[0]?.expert?.expert_description ? el[0]?.expert?.expert_description : 'Эксперт в области бизнеса и личностного роста'}</div>
                                                </div>
                                            </div>
                                            {
                                                
                                                filtredMass.map((task) => (
                                                    <ExpertTask
                                                        uid={task.uid}
                                                        name={task.name}
                                                        status={task.status_id}
                                                        deadlineDate={task.deadline_date}
                                                    />
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <div className="content__noTasks">
                                            <div className="content__noTasks__lottie">
                                                <Lottie
                                                    lottieRef={noFindedTask}
                                                    animationData={prettySmile}
                                                    autoPlay={false}
                                                    loop={false}
                                                />
                                            </div>
                                            <div className="content__noTasks__text">
                                                По заданным критериям задач <br /> не найдено
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    })
                }
            
            </>
        )
    }

    const renderTasks = (obj, isRenderLottie=false) => {
        console.log('obj:', obj)
        const arr = Object.keys(obj)
            .filter((element) => {
                const list = taskList[element];
                if (activeFilters.critical) {
                    return list.findIndex((listElement) => listElement.priority === 1) !== -1
                }
                return true;
            })
            .filter((element) => {
                const list = taskList[element];
                if(activeFilters.date) {
                    return list.find((listElement) =>
                        listElement.deadline_date.getDate() === activeFilters.date.getDate()
                        && listElement.deadline_date.getMonth() === activeFilters.date.getMonth()
                        && listElement.deadline_date.getFullYear() === activeFilters.date.getFullYear()
                    )
                }
                return true;
            })
            .map((key) => (
                <div key={key} className="content__task__wrapper">
                    {
                        key !== 'Просрочено' && (
                            <div className="content__tasklist__title">{key}</div>
                        )
                    }
                    <div className="content__tasklist">
                        {
                            elementsFilters(obj[key])
                                .map((taskElement) => (
                                <Task
                                    key={taskElement.uid}
                                    id={taskElement.id}
                                    uid={taskElement.uid}
                                    isOutdated={isOutdatedActive}
                                    avatar={taskElement.executor_name?.[0] || state.username?.[0].toUpperCase()}
                                    priority={taskElement.priority}
                                    onDelete={() => {
                                        loadTasks();
                                    }}
                                    openModal={() => {
                                        setActiveDeleteTask(taskElement.uid);
                                        setDeleteModalOpen(true);
                                        if(tg) {
                                            tg.HapticFeedback.impactOccurred("medium");
                                        }
                                    }}
                                    text={taskElement.description}
                                    data={taskElement.deadline_date}
                                    onClick={() => {
                                        setTimeout(() => {
                                            navigate(`/detail/${taskElement.uid}`);
                                        }, 50)
                                    }}
                                />
                            ))
                        }
                    </div>
                </div>  
            )
        )
        return arr.length === 0 && isRenderLottie && Object.keys(activeFilters).length !== 0 ? (
            <div className="content__noTasks">
                <div className="content__noTasks__lottie">
                    <Lottie
                        lottieRef={noFindedTask}
                        animationData={prettySmile}
                        autoPlay={false}
                        loop={false}
                    />
                </div>
                <div className="content__noTasks__text">
                    По заданным критериям задач <br /> не найдено
                </div>
            </div>
        ) : arr
    }

    return(
        <div className="main_page">
            <div className="header">
                {
                    isOutdatedActive && <div style={{ fontFamily: 'Arial, sans-serif' }} className="text header__title">Просроченные</div>
                }
                {
                    !isOutdatedActive && (
                        <div className="header__filters">
                            <div
                                // onClick={() => setIsPersonalActive(!isPersonalActive)}
                                className={`header__icon icon__avatar`} onClick={() => {
                                    navigate('/lk')
                                }}>{state.photo ? state.photo : state.first_name[0]?.toUpperCase() }</div>
                            {/* <div
                                onClick={() => setIsAllActive(!isAllActive)}
                                className={`header__icon icon__all ${isAllActive && 'active'}`}><People /></div> */}
                            <div className={`header__tabs ${isOpenExpertList && 'experts'}`}>
                                <div
                                    className={`header__tab ${!isOpenExpertList && 'active'}`}
                                    onClick={() => {
                                        setIsOpenExpertList(false);
                                        setActiveFilter({})
                                    }}
                                >Мои задачи</div>
                                <div
                                    className={`header__tab ${isOpenExpertList && 'active'}`}
                                    onClick={() => {
                                        setIsOpenExpertList(true);
                                        setActiveFilter({})
                                    }}
                                >Экспертные</div>
                            </div>
                            <div
                                onClick={() => {
                                    if(isFilterActive) {
                                        trackEvent('filter', {
                                            status: false,
                                            date: activeFilters.date ? 'custom' : 'default',
                                            critical: activeFilters.critical ? 'custom' : 'default',
                                            archive: activeFilters.archive ? 'custom' : 'default',
                                        });
                                    } else {
                                        trackEvent('filter', {
                                            status: true,
                                            date: activeFilters.date ? 'custom' : 'default',
                                            critical: activeFilters.critical ? 'custom' : 'default',
                                            archive: activeFilters.archive ? 'custom' : 'default',
                                        });
                                    }
                                    setIsFilterActive(!isFilterActive)
                                }}
                                className={`header__icon icon__filter ${isFilterActive && 'active'}`}><Filter /></div>
                        </div>
                    )
                }
                
            </div>
            <div className="content">
                {
                    (isFilterActive) && (
                        <div className="content__filters__overflow">
                            <div className="content__filters">
                                {
                                    (isOpenExpertList ? reducedFiltersList : filtersList).map((filterElement) => {
                                        return filterElement.id === 'date' ? (
                                            <div
                                                className={`content__filter ${activeFilters[filterElement.id] && 'active'}`}
                                                key={filterElement.id}
                                                onClick={() => {
                                                    if (!activeFilters[filterElement.id]) {
                                                        setIsCalendarOpen(true);
                                                        trackEvent('date', {status: true});
                                                    } else if(activeFilters[filterElement.id]) {
                                                        removeElement(filterElement.id)
                                                        trackEvent('date', {status: true});
                                                    }
                                                }}
                                            >
                                                {
                                                    filterElement?.icon && (
                                                        <div className="content__filter__icon">{filterElement.icon}</div>
                                                    )
                                                }
                                                <div className="content__filter__text">{activeFilters[filterElement.id] ? `${activeFilters[filterElement.id].getDate()} ${toHumanFormat(activeFilters[filterElement.id].getMonth())}` : filterElement.text}</div>
                                                {
                                                    filterElement?.secondIcon && (
                                                        <div className="content__filter__icon">{filterElement.secondIcon}</div>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <div
                                                className={`content__filter ${activeFilters[filterElement.id] && 'active'} ${!filterElement.text && 'solo_icon'} ${filterElement.id === 'archive' && 'special__styles'}`}
                                                key={filterElement.id}
                                                onClick={() => {
                                                    if(filterElement.id === 'resetFilter'){
                                                        trackEvent('reset_filter', {
                                                            status: true,
                                                        })
                                                        if(Object.keys(activeFilters).length > 0) {
                                                            removeElement([...Object.keys(activeFilters)])
                                                        }
                                                    } else {
                                                        if (activeFilters[filterElement.id]) {
                                                            removeElement(filterElement.id)
                                                            trackEvent(`${filterElement.id}`, {status: true})
                                                        } else {
                                                            setActiveFilter({...activeFilters, [filterElement.id]: true})
                                                            trackEvent(`${filterElement.id}`, {status: true})
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    filterElement?.icon && (
                                                        <div className={`content__filter__icon ${!filterElement.text && 'solo_icon'}`}>{filterElement.icon}</div>
                                                    )
                                                }
                                                <div className="content__filter__text">{filterElement.text}</div>
                                                {
                                                    filterElement?.secondIcon && (
                                                        <div className="content__filter__icon">{filterElement.secondIcon}</div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    isOpenExpertList && Object.keys(donedExpertTasks).length > 0 && activeFilters?.archive && !activeFilters?.critical && !isOutdatedActive && renderExpertTask(donedExpertTasks)
                }
                {
                    isOpenExpertList && Object.keys(expertTaskList).length > 0 && !activeFilters?.archive && !activeFilters?.critical && !isOutdatedActive && renderExpertTask(expertTaskList)
                }
                {
                    (Object.keys(taskList).length > 0 || Object.keys(outDateTasks).length > 0 || archiveTasks.length > 0) && !isOpenExpertList && (
                        <>
                            {
                                Object.keys(outDateTasks).length > 0 && !isOutdatedActive && !activeFilters?.archive && !activeFilters?.critical && (activeFilters?.date ? checkDate(activeFilters.date) === 'Просрочено' : true) && (
                                    <div className="task__outdated__container">
                                        <Task
                                            key={'outdate'}
                                            preventDelete
                                            uid={'outdate'}
                                            avatar={<Fire />}
                                            priority={0}
                                            text={'Просроченные задачи'}
                                            data={`У вас ${outDateTasks['Просрочено'].length} ${WordsNormalize('задач', outDateTasks['Просрочено'].length, 'female')}, по которым истек срок выполнения`}
                                            onClick={() => {
                                                trackEvent('open_overdue_task', {
                                                    status: true,
                                                })
                                                setIsFilterActive(false);
                                                setActiveFilter({});
                                                setIsOutdatedActive(true);
                                                tg.BackButton.onClick(backBtnFunction)
                                                tg.BackButton.show()
                                            }}
                                        />
                                    </div>
                                )
                            }
                            {
                                !activeFilters?.archive && !isOutdatedActive && renderTasks(taskList, true)
                            }
                            {
                                isOutdatedActive && !activeFilters?.archive && !outDateTasks['Просрочено'] && (
                                    <div className="content__noTasks">
                                        <div className="content__noTasks__lottie">
                                            <Lottie
                                                lottieRef={noFindedTask}
                                                animationData={prettySmile}
                                                autoPlay={false}
                                                loop={false}
                                            />
                                        </div>
                                        <div className="content__noTasks__text">
                                            По заданным критериям задач <br /> не найдено
                                        </div>
                                    </div>
                                )
                            }
                            {
                                isOutdatedActive && !activeFilters?.archive && outDateTasks['Просрочено']?.length !== 0 && renderTasks(outDateTasks, true)
                            }
                            {
                                activeFilters?.archive && archiveTasks.length > 0 && (
                                    <div key={'архивные'} className="content__task__wrapper">
                                        <div className="content__tasklist__title">Архивные задачи</div>
                                        <div className="content__tasklist">
                                            {
                                                elementsFilters(archiveTasks)
                                                .map((taskElement) => (
                                                    <Task
                                                        isStored
                                                        key={taskElement.uid}
                                                        id={taskElement.id}
                                                        uid={taskElement.uid}
                                                        avatar={taskElement.executor_name?.[0] || state.username?.[0].toUpperCase()}
                                                        priority={taskElement.priority}
                                                        onDelete={() => {
                                                            loadClosedTask();
                                                        }}
                                                        openModal={() => {
                                                            setActiveDeleteTask(taskElement.uid);
                                                            setDeleteModalOpen(true);
                                                            if(tg) {
                                                                tg.HapticFeedback.impactOccurred("medium");
                                                            }
                                                        }}
                                                        text={taskElement.description}
                                                        data={taskElement.deadline_date}
                                                        onClick={() => {
                                                            trackEvent('open_task', {
                                                                status: true,
                                                            })
                                                            setTimeout(() => {
                                                                navigate(`/detail/${taskElement.uid}`);
                                                            }, 50)
                                                        }}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {
                    Object.keys(taskList).length === 0 && Object.keys(outDateTasks).length === 0 && !activeFilters?.archive && !isOpenExpertList && isLoadedContent && (
                            <div className="content__noTasks">
                                <div className="content__noTasks__lottie">
                                    <Lottie
                                        lottieRef={ref}
                                        animationData={telegramAnimoji}
                                        autoPlay={false}
                                        loop={false}
                                    />
                                </div>
                                <div className="content__noTasks__text">
                                    В&nbsp;списке&nbsp;пока&nbsp;нет&nbsp;задач.<br />
                                    Скорее жми «Создать» и начни планировать свои задачи.
                                </div>
                            </div>
                    )
                }
                {
                    Object.keys(expertTaskList).length === 0 && !activeFilters?.archive && isOpenExpertList && isLoadedContent && (
                            <div className="content__noTasks">
                                <div className="content__noTasks__lottie">
                                    <Lottie
                                        lottieRef={closeEye}
                                        animationData={close_eye}
                                        autoPlay={false}
                                        loop={false}
                                    />
                                </div>
                                <div className="content__noTasks__text">
                                    Пока&nbsp;от&nbsp;эксперта&nbsp;нет&nbsp;задач.<br />
                                    Новые задачи можно получить, подписавшись на эксперта.
                                </div>
                            </div>
                    )
                }
                {
                    (!isOpenExpertList && activeFilters?.archive && archiveTasks.length === 0) && isLoadedContent &&(
                        <div className="content__noTasks">
                            <div className="content__noTasks__lottie">
                                <Lottie
                                    lottieRef={smileRef}
                                    animationData={prettySmile}
                                    autoPlay={false}
                                    loop={false}
                                />
                            </div>
                            <div className="content__noTasks__text">
                                Кажется, в архиве нет ни одной <br /> задачи
                            </div>
                        </div>
                    )
                }
                {
                    (isOpenExpertList && activeFilters?.archive && donedExpertTasks.length === 0) && isLoadedContent &&(
                        <div className="content__noTasks">
                            <div className="content__noTasks__lottie">
                                <Lottie
                                    lottieRef={smileRef}
                                    animationData={prettySmile}
                                    autoPlay={false}
                                    loop={false}
                                />
                            </div>
                            <div className="content__noTasks__text">
                                Кажется, в архиве нет ни одной <br /> задачи
                            </div>
                        </div>
                    )
                }
                {
                    (
                        activeFilters?.archive
                            ? archiveTasks.length === 0
                            : Object.keys(taskList).length === 0
                    ) && !isLoadedContent && (
                        <div className="content__tasks">
                            <div className="content__task__wrapper">
                                <div className="content__tasklist__title bones"></div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist__title bones"></div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content__tasklist">
                                    <div className="content_simple_wrapper">
                                        <div className="content__task">
                                            <div className="content__task__avatar bones"></div>
                                            <div className="content__task__content">
                                                <div className="content__task__header bones" />
                                                <div className="content__task__middle bones" />
                                                <div className="content__task__data bones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    )
                }
                {
                    !isOpenExpertList && isLoadedContent && (
                        <div
                            onClick={() => {
                                trackEvent('create_new_task', {
                                    number_of_tasks: Object.keys(taskList).length,
                                })
                                navigate('/new')
                            }}
                            className={`create_task ${isBottomBtn && 'create__task__bottom'}`}
                        ></div>
                    )
                }
                {
                    isOpenExpertList && (
                        <div
                            onClick={() => {
                                trackEvent('create_new_task', {
                                    number_of_tasks: Object.keys(taskList).length,
                                })
                                navigate('/marketplace')
                            }}
                            className={`create_task prevent_plus ${isBottomBtn && 'create__task__bottom'}`}
                        ><Experts /></div>
                    )
                }
                
            </div>
            <Modal 
                isOpen={deleteModalOpen}
                title="Вы точно хотите удалить эту задачу?"
                buttons={[
                    {
                        text: 'Отмена',
                        onClick: () => {
                            setDeleteModalOpen(false);
                            setActiveDeleteTask(null)
                        },
                        type: 'normal'
                    },
                    {
                        text: 'Удалить',
                        onClick: () => {
                            fetch(`${config.src}/tasks/id/${activeDeleteTask}/`, {
                                method: 'DELETE',
                            })
                                .then(() => {
                                    setDeleteModalOpen(false);
                                    if (activeFilters?.archive) {
                                        loadClosedTask();
                                    } else {
                                        loadTasks();
                                    }
                                })
                                .catch((err) => {
                                    console.log('err');
                                })
                        },
                        type: 'bold'
                    }
                ]}
            />
            <BottomNotify />
            {
                isCalendarOpen && (
                    <CustomCalendar
                        closeModal={() => {setIsCalendarOpen(false)}}
                        isOpen={isCalendarOpen}
                        setDate={(date) => setActiveFilter({...activeFilters, date})}
                        disableTime
                        isVisibleToday
                    />
                )
            }
        </div>
    )
}