import './index.css'
import { useTelegram } from "../../hooks/useTelegram"
import React, {useState, useEffect, useRef} from 'react'
import ArrowNoFill from '../../images/Arrow_nofill';
import config from '../../config'
import WordsNormalize from '../../utils/wordsNormalize';
import { setCurrentPage } from "../../slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import BottomNotify from '../../components/bottomNotify';


const filterMass = (array, categoryFilter, currentFilters, inputValue) => {
    let arrCopy = [...array];
    let counter = [];
    for(let i = 0; i < categoryFilter.current.length; i++) {
        if(currentFilters[categoryFilter.current[i].id]) {
            counter.push(categoryFilter.current[i].id);
        }
    }
    if(counter.length > 0) {
        arrCopy = arrCopy.filter((category) => counter.indexOf(category.id) !== -1)
    }

    arrCopy = arrCopy.filter((category) => category.experts.filter((expert) => (currentFilters.moreThanFive ? expert.task_count > 5 : true) && `${expert.expert_first_name} ${expert.expert_last_name}`.toLowerCase().includes(inputValue.toLowerCase())).length > 0)
    return arrCopy
}


export default function Marketplace() {
    const [marketplace, setMarketplace] = useState([]);
    const [filters, setFilters] = useState([]);
    const [currentFilters, setCurrentFilters] = useState({all: true});
    const [isOpenList, setIsOpenList] = useState({});
    const [filtredExperts, setFiltredExperts] = useState({});
    const [inputValue, setInputValue] = useState('');
    const categoryFilter = useRef([])
    const user =  useSelector((store) => store.userSlice);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { tg } = useTelegram();

    useEffect(() => {
        dispatch(setCurrentPage(window.location.href))
    // eslint-disable-next-line
    }, [])

    const backFunction = () => {
        navigate('/');
    }

    const src = config.src.split('/').splice(0, 3).join('/');

    useEffect(() => {
        tg.BackButton.onClick(backFunction)
        tg.BackButton.show()

        return () => {
            tg.BackButton.offClick(backFunction)
        }
    // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if(user.telegram_id) {
            console.log('user:', user.telegram_id);
            fetch(`${config.src}/market/experts/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "group" : "category",
                    "user_id" : user.telegram_id,
                }),
            })
            .then(async (res) => {
                let re = await res.json();
                console.log('marketplace:', [...re.filter((el) => el.experts)]);
                setMarketplace([...re.filter((el) => el.experts)])
            })
        }
        
    }, [user.telegram_id])

    useEffect(() => {
        if(marketplace) {
            const currFilter = [
                {
                    id: 'all',
                    name: 'Все'
                }
            ];
            let filtredExpertsCurrent = {};
            marketplace.forEach((element) => {
                currFilter.push({
                    id: element.id,
                    name: element.name,
                })
                categoryFilter.current.push({
                    id: element.id,
                    name: element.name,
                })
                filtredExpertsCurrent[element.id] = [...element.experts]
            })
            currFilter.push({
                id: 'moreThanFive',
                name: '5+ тем',
            })
            setFiltredExperts(filtredExpertsCurrent)
            setFilters(currFilter)
        }          
    }, [marketplace])

    useEffect(() => {
        if(marketplace) {
            let filtredExpertsCurrent = {};
            marketplace.forEach((element) => {
                filtredExpertsCurrent[element.id] = [...element.experts.filter((expert) => `${expert.expert_first_name} ${expert.expert_last_name}`.toLowerCase().includes(inputValue.toLowerCase()))];
                if(currentFilters.moreThanFive) {
                    filtredExpertsCurrent[element.id] = filtredExpertsCurrent[element.id].filter((element) => element.task_count > 5)
                }
            })
            setFiltredExperts(filtredExpertsCurrent);
            console.log('filtredExpertsCurrent:', filtredExpertsCurrent);
        }
    }, [currentFilters.moreThanFive, inputValue, marketplace])

    return (
        <div className="marketplace">
            <div className="marketplace__header">Эксперты</div>
            <div className="marketplace__search">
                <input
                    className="marketplace__input"
                    placeholder="Введите имя эксперта"
                    type="text"
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                />
            </div>
            <div className="marketplace__filters__wrapper">
                <div className="marketplace__filters">
                    {
                        filters.map((element) => (
                            <div
                                key={element.id}
                                className={`marketplace__filter ${currentFilters[element.id] && 'active'}`}
                                onClick={() => {
                                    if(element.id === 'all') {
                                        if(!currentFilters[element.id]) {
                                            setCurrentFilters({all: true})
                                        }
                                    } else {
                                        const currFil = {...currentFilters}
                                        delete currFil.all
                                        if(!currentFilters[element.id]) {
                                            setCurrentFilters({...currFil, [element.id]: true});
                                        } else {
                                            setCurrentFilters({...currFil, [element.id]: false});
                                        }
                                    }
                                }}
                            >{element.name}</div>
                        ))
                    }
                </div>
            </div>
            <div className="marketplace__block">
                {
                    filterMass(marketplace, categoryFilter, currentFilters, inputValue)
                    .map((category) => (
                        <div className="marketplace__wrapper" key={category.id}>
                            <div className="marketplace__wrapper__title">{category.name}<span>{filtredExperts[category.id]?.length}</span></div>
                            <div
                                className="marketplace__experts"
                                style={{
                                    height: `${isOpenList[category.id] ? Math.ceil(filtredExperts[category.id]?.length / 2) * 205 + 17 : (filtredExperts[category.id]?.length < 3 ? 1 : 2) * 205 + 17}px`
                                }}
                            >
                                {
                                    (filtredExperts[category.id] || [])
                                    .map((expert) => (
                                        <div
                                            key={expert.expert_id}
                                            className="marketplace__expert"
                                            style={{
                                                backgroundImage: `url(${src}/avatars/${expert?.photo})`,
                                            }}
                                            onClick={() => {
                                                navigate(`/expertDetail/${expert.expert_id}`)
                                            }}
                                        >
                                            <div className="marketplace__expert__title">{expert?.expert_first_name} {expert?.expert_last_name}</div>
                                            <div className="marketplace__expert__describe">{expert?.expert_description}</div>
                                            <div className="marketplace__expert__tasks">{!expert?.subscribed ? expert?.task_count < 6 ? `${expert?.task_count} ${WordsNormalize('задач', expert?.task_count, 'female')}` : `5+ задач` : 'Вы подписаны'}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                filtredExperts[category.id]?.length > 4 && (
                                    <div onClick={() => {
                                        if(isOpenList[category.id]) {
                                            setIsOpenList({...isOpenList, [category.id]: false})
                                        } else {
                                            setIsOpenList({...isOpenList, [category.id]: true})
                                        }
                                    }} className="marketplace__show__more"><p>{isOpenList[category.id] ? 'Скрыть' : 'Показать еще'}</p><span className={`${isOpenList[category.id] && 'rotate'}`}><ArrowNoFill /></span></div>
                                )
                            }
                        </div>
                    ))
                }
            </div>
            {
                Object.values(filtredExperts).filter((el) => el.length).length === 0 && (
                    <div className="marketplace__empty">
                        <div className="marketplace__img"></div>
                        <div className="marketplace__text">
                        К сожалению, при поиске по заданным праметрам, мы не смогли найти эксперта.
                        </div>
                    </div>
                )
            }
            <BottomNotify />
        </div>
    )
}